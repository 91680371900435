import {
    Box,
    Button,
    TextField,
    Typography,
    Stack,
    Container,
    InputAdornment,
    IconButton
} from '@mui/material'
import logo from '../../../../../assets/logo.png'

import styles from './NewUserPasswordStyles'

import { useNavigate } from 'react-router-dom'
import { useState, useContext } from 'react'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {SnackbarContext} from "../../../../../hooks/SnackbarContext";
import {AuthHelper, CognitoUserType} from "../../../../../services/AuthHelper";
import Colors from "../../../../../assets/Colors";

const passwordRegex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/

function NewUserPassword({ user }: { user: CognitoUserType }) {
    const navigate = useNavigate()

    const [newPassword, setNewPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [viewNewPassword, setViewNewPassword] = useState<boolean>(false)
    const [viewConfirmPassword, setViewConfirmPassword] = useState<boolean>(false)
    const { addSnack } = useContext(SnackbarContext)

    const disableLogin =
        !newPassword ||
        newPassword !== confirmPassword ||
        newPassword.length < 8 ||
        !passwordRegex.test(newPassword)

    function iconColor(): string {
        if (!(newPassword && confirmPassword)) { return Colors.napaGrey }
        return Colors.napaGreenDark
    }

    async function createNewPassword(): Promise<void> {
        try {
            await AuthHelper.completePasswordChallenge(user, newPassword)

            addSnack({
                severity: 'success',
                message: 'Success! Your password has been updated',
                action: null,
                duration: 3000
            })
            navigate('/')
        } catch (error) {
            console.error(error)
            addSnack({
                severity: 'error',
                message: 'Unknown service error, please try again.',
                action: null,
                duration: 3000
            })
        }
    }

    return (
        <>
            <Container sx={styles.forgotPasswordContainer}>
                <Stack style={styles.forgotPasswordStack}>
                    <Box>
                        <img src={logo} alt='PROTracker Logo' style={styles.logo} />
                    </Box>
                    <Box>
                        <Typography variant={'h4'} style={styles.header} data-testid="forgot-text">
                            Create Password
                        </Typography>
                    </Box>

                    <Box
                        width={{ xs: '300px', sm: '350px' }}
                        component={'form'}
                        noValidate={true}
                        sx={styles.submitContainer}
                    >
                        <TextField
                            autoComplete="false"
                            label={'New Password*'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setViewNewPassword(prevState => !prevState)}>
                                            {viewNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            value={newPassword}
                            id="new-password"
                            margin="normal"
                            data-testid="new-password"
                            type={viewNewPassword ? 'text' : 'password'}
                            inputProps={{ style: styles.input }}
                            sx={{
                                width: '330px',
                                height: '48px',
                                mb: '15px'
                            }}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <TextField
                            autoComplete="false"
                            type={viewConfirmPassword ? 'text' : 'password'}
                            label={'Confirm Password*'}
                            value={confirmPassword}
                            data-testid="confirm-password"
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setViewConfirmPassword(prevState => !prevState)}>
                                            {viewConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            inputProps={{ style: styles.input }}
                            sx={{
                                width: '330px',
                                height: '48px',
                                mb: '15px'
                            }}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            // onBlur={handleBlur}
                        />
                        <Box sx={styles.passwordContainer}>
                            <Typography style={styles.passwordHeader} data-testid="forgot-text">
                                Password requirements
                            </Typography>
                            <Typography sx={styles.passwordText} data-testid="forgot-text">
                                {newPassword && newPassword.length < 8 ? (
                                    <ErrorOutlineOutlinedIcon fontSize="small" color="secondary" />
                                ) : (
                                    <CheckCircleOutlineOutlinedIcon fontSize="small" sx={{ color: iconColor() }} />
                                )}
                                &nbsp; Passwords must be at least 8 charaters long.
                            </Typography>
                            <Typography sx={styles.passwordText} data-testid="forgot-text">
                                {newPassword && !passwordRegex.test(newPassword) ? (
                                    <ErrorOutlineOutlinedIcon fontSize="small" color="secondary" />
                                ) : (
                                    <CheckCircleOutlineOutlinedIcon fontSize="small" sx={{ color: iconColor() }} />
                                )}
                                &nbsp; Must include at least one (1) number, one (1) upper case &nbsp; letter and
                                one (1) lower case letter.
                            </Typography>
                        </Box>
                        <Button
                            onClick={createNewPassword}
                            variant="contained"
                            sx={styles.login}
                            disabled={disableLogin}
                            data-testid="create-password-button"
                        >
                            Create Password & Sign In
                        </Button>
                    </Box>
                </Stack>
            </Container>
        </>
    )
}

export default NewUserPassword
